import { axios } from '@/utils/request'
const api = {
    GetMedia: "services/app/EnglishRecordService/GetMedia",
    GetEngTagsByParentId: "services/app/EnglishRecordService/GetEngTagsByParentId"
}

export function getMedia(parameter) {
    return axios({
        url: api.GetMedia,
        method: "get",
        params: parameter,
    });
}

export function getEngTagsByParentId(parameter) {
    return axios({
        url: api.GetEngTagsByParentId,
        method: "get",
        params: parameter,
    });
}