<template>
  <div class="wrapper-video">
    <b-container fluid class="wrapper-content">
      <b-row>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane disabled label="战备体检" name="1"></el-tab-pane>
          <el-tab-pane label="扫雷尖兵" name="2"></el-tab-pane>
          <el-tab-pane disabled label="沙漠行动" name="4"></el-tab-pane>
          <el-tab-pane disabled label="抢滩登陆" name="7"></el-tab-pane>
        </el-tabs>
      </b-row>
      <b-row align-h="between" class="video-title">
        <b-col align-self="center" cols="12" sm="6">
          <span class="iconfont icon-tongji"></span>
          <span>视频数量：</span>
          <span class="video-quantity">{{ dataMedia.mediaCount }}</span>
        </b-col>
        <b-col align-self="center" sm="6">
          <span class="grammatical-point">语法点：</span>
          <el-select
            v-model="questionType"
            placeholder="请选择"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(item, i) in dataLabel"
              :key="i"
              :label="item.description"
              :value="i"
              @click.native="questionTypeChange(item.tag)"
            >
            </el-option>
          </el-select>
          <span class="search-button" @click="search"> 搜 索 </span>
        </b-col>
      </b-row>
      <el-table
        :data="tableData"
        :row-class-name="tableRowClassName"
        style="width: 100%; min-height: 40.6rem"
      >
        <el-table-column prop="classB" label="语法点" min-width="15%">
        </el-table-column>
        <el-table-column
          prop="questionStatement"
          label="微课视频"
          min-width="60%"
        >
          <template slot-scope="scope">
            <span v-html="scope.row.questionStatement"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="length" label="时长（分钟）" min-width="10%">
          <template slot-scope="scope">
            <span>{{
              Math.round(
                (scope.row.videoList
                  .map(item => item.length)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  ) /
                  60) *
                  10
              ) / 10 || "0.0"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="微课视频" min-width="10%">
          <template slot-scope="scope">
            <span @click="openDialog(scope.row)" class="iconfont icon-bofang2">
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="微课视频"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        custom-class="micro-lesson-video"
        width="50%"
        :modal="false"
        v-dialogDrag
        center
        :lock-scroll="false"
        :close-on-click-modal="false"
        @close="closeDialog"
      >
        <div class="cards-bottom">
          <div class="cards-left">
            <el-menu :default-openeds="['0']">
              <el-submenu index="0">
                <template slot="title">
                  <i class="iconfont icon-pinglun" aria-hidden="true"></i>
                  <span style="margin-left: 10px">微课视频</span>
                </template>
                <el-menu-item
                  v-for="(item2, index2) in videoUrl"
                  :key="index2 + '-' + index2"
                  :index="index2 + '-' + index2"
                  @click="changeUrl(index2, index2)"
                >
                  <span>{{ item2.category + (index2 + 1) }}</span>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
          <div
            v-for="(item, index) in videoUrl"
            v-show="index == lockIndex"
            :key="index"
            class="card-right"
          >
            <template v-for="(item2, index2) in videoUrl">
              <div
                v-if="index == lockIndex && index2 == lockIndex2"
                :key="index + '-' + index2"
                class="play-frame"
              >
                <div style="height: 100%">
                  <iframe
                    :src="'/azmediaplayer.html?url=' + item2.url"
                    scrolling="no"
                    frameborder="no"
                    align="center"
                    class="video-iframe"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-dialog>
      <div class="bottom-content">
        <el-pagination
          :current-page.sync="pageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataMedia.totalCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <!-- :hide-on-single-page="data.length < pageSize" -->
        </el-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import { getMedia, getEngTagsByParentId } from "@/api/english-video";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      activeName: "2",
      questionType: "",
      pageIndex: 1,
      pageSize: 10,
      dataLabel: [],
      grammarId: 0,
      tableData: [],
      dialogVisible: false,
      videoUrl: "",
      dataMedia: "",
      lockIndex: 0,
      lockIndex2: 0,
    };
  },
  mounted() {
    // this.getMediaVideo();
    this.getParentIdVideo();
    this.handleClick();
  },
  computed: {
    ...mapState(["currentModuleId"]),
  },
  methods: {
    ...mapMutations(["setCurrentModuleId"]),
    changeUrl(index, index2) {
      this.lockIndex = index;
      this.lockIndex2 = index2;
    },
    getMediaVideo() {
      let parmeter = {
        ModuleId: 2, //this.currentModuleId
        ClassAId: 2,
        ClassBId: this.grammarId,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      getMedia(parmeter).then((res) => {
        this.dataMedia = res.data.result;
        this.tableData = res.data.result.records;
        console.log("视频列表", res);
      });
    },
    // 语法点
    getParentIdVideo() {
      getEngTagsByParentId({ parentId: 8 }).then((res) => {
        console.log("语法点", res);
        this.dataLabel = res.data.result;
      });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    // 下拉框 语法
    questionTypeChange(id) {
      console.log("id", id);
      this.grammarId = id;
      this.getMediaVideo();
    },
    // 点击微课视频图标
    openDialog(row) {
      console.log("微课视频", row);
      this.videoUrl = row.videoList;
      this.dialogVisible = true;
    },
    // 搜索
    search() {
      this.getMediaVideo();
    },
    // getData() {
    //   this.activeName = 2; //"" + this.currentModuleId
    // },
    // 下拉框 显示多少条
    handleSizeChange(size) {
      console.log("pageSize", size);
      this.pageSize = size;
      this.pageIndex = 1;
      this.getMediaVideo();
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getMediaVideo();
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.pageIndex = 1;
      this.setCurrentModuleId(this.activeName);
      this.getMediaVideo();
    },
    // 隔行换色
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "bg-green";
      }
      return "bg-white";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-video {
  width: 100%;
  height: 100%;
  padding: 0 4%;
  .wrapper-content {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    .row {
      margin: 0;
    }
  }
  .video-title {
    background: #f5f5f5;
    .align-self-center {
      display: flex;
      align-items: center;
    }
    & > .align-self-center:nth-child(1) {
      justify-content: flex-start;
      padding-left: 2rem;
    }
    & > .align-self-center:nth-child(2) {
      justify-content: flex-end;
      padding-right: 2rem;
    }
  }
  .icon-tongji {
    color: #fbd876;
    font-size: 2.3rem;
  }
  .video-quantity {
    font-size: 1.6rem;
    color: #2cb774;
    font-weight: bold;
  }
  .grammatical-point {
    font-weight: bold;
    font-size: 1.15rem;
  }
  .search-button {
    font-size: 1.15rem;
    color: #fff;
    background: #fecc42;
    border-radius: 5px;
    padding: 4px 20px;
    margin-left: 1rem;
    cursor: pointer;
  }
  .icon-bofang2 {
    color: #f9af2d;
    font-size: 1.7rem;
  }
  .bottom-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }
  .video-iframe {
    height: 100%;
    width: 100% !important;
  }
}
</style>
<style lang="scss">
.wrapper-video {
  .row,
  .el-tabs {
    width: 100%;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .el-input__inner {
    // width: 244px;
    height: 31px;
    line-height: 31px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
  }
  .el-input__icon {
    line-height: 31px;
  }
  .bg-green {
    background-color: #eef9f4 !important;
    cursor: pointer;
    .is-center {
      padding: 7px 0;
    }
    & > td:nth-child(2) {
      text-align: left;
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      border: none !important;
      background: none !important;
    }
  }
  .bg-white {
    cursor: pointer;
    & > td:nth-child(2) {
      text-align: left;
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      border: none !important;
      background: none !important;
    }
  }
  .el-table th.is-leaf,
  .el-table td {
    text-align: center;
  }
  .micro-lesson-video {
    overflow: hidden !important;
    height: 38rem;
    .el-dialog__header {
      background-color: #2cb774;
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
    .el-dialog__title {
      color: #fff !important;
    }
    .el-dialog__headerbtn {
      top: 10px;
    }
    .el-dialog__body {
      height: 28rem;
      padding: 0;
    }
    // .play-frame {
    //   margin: 0 auto;
    //   height: 100%;
    // }
  }
  .cards-bottom {
    display: flex;
    border-radius: 10px;
    margin: 10px;
  }
  .cards-left {
    width: 250px;
    margin-right: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
  }
  .card-right {
    width: 100%;
    height: 550px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  .play-frame {
    margin: 0 auto;
    height: 100%;
  }
  .el-icon-close:before {
    color: #E5E5E5;
  }
}
</style>